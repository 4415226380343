import React from 'react';
import { useState, useContext } from 'react';
import { useEffect } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { useDebounce } from 'use-debounce';
import JobNav from './JobNav';
import { useLocation } from 'react-router-dom';
import ConfirmationDialog from './ConfirmationDialog';

import ConfirmationNeokey from './ConfirmationNeokey';
import { useNavigate } from 'react-router-dom';
import deLocale from '../de.json';
import { UserContext } from '../User';
import { common } from '@material-ui/core/colors';

interface LocalizationData {
  [key: string]: string;
}

interface CaseProps {
  casefiles?: any;
  case_id?: any;
  compound_mode?: any;
  res?: any;
}

interface QueueItem {
    case_id: any; 
    i? : {}; 
}

interface RadioOption {
  label: string;
  value: string;
}

type DocType = {
  doc_name: string ;
  status: number;
  download_link: string;
  doc_id: string;
  fsize: number;
};

const API_URL = (window as any).API_URL;
const DOWNLOAD_URL = (window as any).DOWNLOAD_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

const Dropzone = ({case_id}:CaseProps) => {

  //const files = {casefiles}

  const route = useLocation();
  const state = route.state;
  console.log(state);

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';

  const { UserData, updateUserData } = useContext(UserContext);

  let orgaid = "";
  let tokenHash = "";
  let userid = "";
  let licence = ""

  if(UserData){
    tokenHash = UserData.payload ? UserData.payload.token : undefined;
    userid = UserData.payload ? UserData.payload.hashid :  undefined;
    licence = UserData.payload ? UserData.payload.licence:  undefined;
    orgaid = UserData.payload ? UserData.payload.org:  undefined;
  }

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(Boolean);
  const [key, setKey] = useState(0);
  const [debounceKey] = useDebounce(key, 1000);
  const [visibleclassprogress, setVisibleclassprogress] = useState('opacity-0 h-0 p-0 ');
  const [visibleclassfinish, setVisibleclassfinish] = useState('opacity-0 h-0 p-0');

  const [neokey, setNeoKey] = useState("");
  const [compoundMode, setCompoundMode] = useState('0');
  const [caseid, setCaseid] = useState("");
  const [response, setResponse] = useState("");
  const [disabled, setDisabled] = useState(Boolean);
  const [disableSubmit, setDisableSubmit] = useState(Boolean);
  
  const [reverse, setReverse] = useState(false);

  const [fileList, setFileList] = useState<File[]>([])
  const [filescount, setFilescount] = useState(Number)
  
  const options: RadioOption[] = [
    { label: getLocalizedString('pseudonymize', currentLanguage), value: '0' },
    { label: getLocalizedString('anonymize', currentLanguage), value: '1' },
    { label: getLocalizedString('reverse', currentLanguage), value: '2' },
  ];

  

function handleCopyClickFallback(neokeyvalue:any) {
  var textArea = document.createElement("textarea");
  textArea.value = neokeyvalue;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
  }

  document.body.removeChild(textArea);
}

function handleCopyClick(neokeyvalue:any) {
  if (!navigator.clipboard) {
    handleCopyClickFallback(neokeyvalue);
    return;
  }
  navigator.clipboard.writeText(neokeyvalue).then(function() {

  }, function(err) {

  });
}

  const [selectedOption, setSelectedOption] = useState<string>('0');

  function NeokeyMessage (neokey:any){
  if(neokey && neokey !== '' && selectedOption === '0'){
    setOpenDialog(true)
  } else {
    setOpenDialog(false)
  }
};

  if(selectedOption === '2' && !reverse){
    setReverse(true)
  }
  
  if(selectedOption !== '2' && reverse){
    setReverse(false)
  }

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const mode = state.compound_mode;
    setCompoundMode(mode);
  }, []);

  useEffect(() => {
    setFileList([])
    if(case_id !== undefined){
      setCaseid(case_id)
      setDisabled(true)
    }else if(sessionStorage.getItem('caseid') !=='' && state !=='new') {
      setCaseid(sessionStorage.getItem('caseid')|| '')
      setDisabled(true)
    } else if (state ==='new') {
      setCaseid('')
      setDisabled(false)
    }
  }, [case_id,state]);

  useEffect(() => {
    const caseidSubmit = document.getElementById("caseSubmit") as HTMLInputElement | null;
    if(caseidSubmit && fileList.length > 0){
      caseidSubmit.disabled=false
    }else if (caseidSubmit && fileList.length < 1){
      caseidSubmit.disabled=true
    }
  }, [fileList]);

  const [running, setRunning] = useState(Boolean);
  const [refreshData, setRefreshData] = useState('');

  useEffect(() => {
    
    if(NEOTOKEN && NEOTOKEN !=''){
      const formData = new FormData();

      if (licence !== null && tokenHash !== null && userid !== null) {
        formData.append("userid", userid );
        formData.append("token_hash", tokenHash);
        formData.append("licence", licence);
        
      }
      
      formData.append("neotoken", NEOTOKEN);

      const apiurl = API_URL+'refresh';

      if(running){
      // console.log(running)
      }else{

      setRunning(true)
      fetch(apiurl, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
            //console.log(data);
              setRefreshData(data) 
              setRunning(false)

              if(refreshData && JSON.parse(refreshData ).errorcode > 0 ){
                console.log(JSON.parse(refreshData ).msgclear);
                updateUserData({})
                navigate('/');
              }

            }
        )
        
        .catch((err) => {
            //console.log(err);
        });

        if(refreshData && JSON.parse(refreshData ).errorcode > 0 ){
          console.log(JSON.parse(refreshData ).msgclear);
          updateUserData({})
          navigate('/');
        }
    }

  }

    const interval = setInterval(() => {
      
      if(NEOTOKEN && NEOTOKEN !=''){
        const formData = new FormData();
        if (licence !== null && tokenHash !== null && userid !== null) {
          formData.append("userid", userid );
          formData.append("token_hash", tokenHash);
          formData.append("licence", licence);
        }
        formData.append("neotoken", NEOTOKEN);

        const apiurl = API_URL+'refresh';

        if(running){
        // console.log(running)
        }else{

        setRunning(true)
        fetch(apiurl, {
          method: 'POST',
          body: formData,
        })
          .then((res) => res.text())
          .then((data) => {
                setRefreshData(data) 
                setRunning(false)

                if(refreshData && JSON.parse(refreshData ).errorcode > 0 ){
                  console.log(JSON.parse(refreshData ).msgclear);
                  updateUserData({})
                  navigate('/');
                }

              }
          )
          
          .catch((err) => {
              //console.log(err);
          });

          if(refreshData && JSON.parse(refreshData ).errorcode > 0 ){
            console.log(JSON.parse(refreshData ).msgclear);
            updateUserData({})
            navigate('/');
          }
      }

    }

  }, 3000);
 
  return () => clearInterval(interval);
 
  }, [UserData, refreshData]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCaseid(event.target.value)
    const newCase = <JobNav JobName={caseid} compound_mode={compoundMode}/>
  }

  const handleConfirm = (item: string) => {
  };

  let groupedFiles=[];

  if (refreshData) {
    const json = JSON.parse(refreshData);
    console.log('_id: '+case_id)
    console.log('id: '+caseid)
    if(json.payload.queue && case_id !== ''){
      groupedFiles = json.payload.queue.filter((i:any) => (caseid === i.case_id));
    }else if(json.payload.queue && caseid !== ''){
      groupedFiles = json.payload.queue.filter((i:any) => (caseid === i.caseid));
    }else if(caseid! && !case_id){
      setCaseid('ddd') 
      groupedFiles = json.payload.queue.filter((i:any) => (caseid === i.caseid));
    }

  }else{
    localStorage.clear();
    //navigate('/');
  }

  function deleteItem(doc_id:string) {

      const formData = new FormData();

      if (tokenHash && userid && orgaid) {
        formData.append("itemid", doc_id)
        formData.append("userid", userid );
        formData.append("token_hash", tokenHash);
        formData.append("orgaid", orgaid);
        if (licence !== null) {
          formData.append("licence", licence);
        }
        formData.append("neotoken", NEOTOKEN);
      }

      const apiurl = API_URL+'deleteitem';

      fetch(apiurl, {
        method: 'POST',
        body: formData
      })
        .then((res) => res.text())
        .then((data) => {
            //console.log(data);
            if(data){
                let element = document.body.querySelector('[data-id="'+doc_id+'"]') as HTMLElement | null;
                if (element) {
                  element.style.display = 'none';
                }
            }

        })
        .catch((err) => {
            console.log(err.message);
            //navigate('/');
        });
      
    }


  function handleSubmit(e: React.FormEvent) {
    
    e.preventDefault();
    setResponse('')
    setVisibleclassprogress('opacity-100 h-50 p-4');
    setVisibleclassfinish('opacity-0 h-0 p-0');

    if(document.querySelector('.MuiDropzonePreviewList-root')){
      document.querySelector('.MuiDropzonePreviewList-root')!.classList.add('hidden');
    }

    if (e.target instanceof HTMLFormElement) {
      
      const formData = new FormData(e.target);

      formData.delete("file");
      formData.delete("files");
     
      if (tokenHash && userid) {

        formData.append("userid", userid );
        formData.append("token_hash", tokenHash);
        formData.append("licence", licence);
        formData.append("neotoken", NEOTOKEN);
        formData.append("compound_mode", compoundMode);

        if(!formData.get('caseid')){
            formData.delete("caseid");
            formData.append("caseid",case_id || caseid);
        }

        sessionStorage.setItem('caseid', case_id || caseid)
        //setState('current')

        var filesLength=Array.from(fileList).length;
        for(var i=0;i<filesLength;i++){
          formData.append("file[]", Array.from(fileList)[i]);
          formData.append('files[]', Array.from(fileList)[i]);
        }

      }

      setKey(Date.now())

      const apiurl = API_URL+'upload';
      // Using Fetch API
      fetch(apiurl, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
            //console.log(data);
            if(data){
              let dataJson = JSON.parse(data)

              if(dataJson.errorcode === 0){
                setFileList([]);
                fileList.length = 0;
              }

              setNeoKey(dataJson.payload[0].neokey)

              setVisibleclassprogress('opacity-0 w-full h-0 p-0');
              setVisibleclassfinish('opacity-100 w-full h-50 p-4');

              setTimeout(() => {
                setVisibleclassfinish('opacity-0 w-full h-0 p-0');
              }, 4000);
              
              NeokeyMessage(dataJson.payload[0].neokey);

              const caseidInput = document.getElementById("caseid") as HTMLInputElement | null;
              caseidInput!.disabled=true;
              navigate('/Case',{ state: sessionStorage.getItem('caseid')  });

            }

        })
        .catch((err) => {
          setVisibleclassprogress('opacity-0 w-full h-0 p-0');
          setResponse(
            '<div class="my-3 bg-red-500 opacity-1 text-sm text-white rounded-lg p-4 transition-all" role="alert"><b>'+
            err.message+
            '</b></div>');

        });
        setTimeout(() => {
          setResponse('');
        }, 4000);
      }
    }

    var alert_del = document.querySelectorAll('.alert-del');
    alert_del.forEach((x) =>
      x.addEventListener('click', function () {
        x.parentElement!.parentElement!.classList.add('hidden');
      })
    );

    const messages = {
        dropzoneText: 'Datei hier hereinziehen oder klicken',
        dropzoneParagraph: 'Unterstützte Dateitypen: JPEG, PNG',
        previewText: 'Vorschau:',
        removeFileText: 'Entfernen',
        fileTooBig: 'Diese Datei ist zu groß. Maximale Dateigröße für den Upload: {{maxFileSize}} MB.',
        filesLimit: 'Zu viele Dateien. Limit ist {{maxFiles}} Dateien.',
        unsupportedFileType: 'Datei kann nicht verarbeitet werden. Der Dateityp wird nicht unterstützt. Bitte laden Sie nur Dateien der Typen {{acceptedFiles}} hoch.',
    };
    
    

return(
<>
<JobNav key={caseid+compoundMode} JobName={caseid} compound_mode={state.compound_mode}/>
<div className="p-10 xl:flex"> 

  <form 
    className="form h-[calc(100vh-200px)] rounded-xl dark:bg-slate-900 bg-white w-[550px] shrink-0"
    onSubmit={handleSubmit}
    encType="multipart/form-data"
    id="myform"
    >
    <div className="h-[calc(100vh-200px)] relative">
      <div className="lg:flex flex-col shrink-0 flex-grow-y justify-center items-center pt-[var(--Utilities-Height-5,] pb-[var(--Navigations-Sidebar-Footer-Spacing-X,] pl-[20px)] pr-[20px)] p-5 dark:bg-slate-900">
        <div className="flex flex-col items-start self-stretch">
          
          <DropzoneArea
              onChange={(files) => setFileList(files)}
              dropzoneClass = 'p-4 mb-4 bg-slate-900 bg-transparent rounded-xl'
              filesLimit={100}
              maxFileSize={50000000}
              dropzoneText={getLocalizedString('dropFiles', currentLanguage)}
              acceptedFiles={[ ".doc", ".docx" ]}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: { spacing: 1, direction: 'column' }}}
              alertSnackbarProps = {{ anchorOrigin : {vertical: 'top', horizontal: 'left'}} }
              disableRejectionFeedback
              clearOnUnmount={true}
              key={debounceKey}
              showAlerts={['error']} 
              getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                if (rejectedFile.size > maxFileSize) {
                  return messages.fileTooBig.replace('{{maxFileSize}}', (maxFileSize / 1000000).toString());
                }
                return messages.unsupportedFileType.replace('{{acceptedFiles}}', acceptedFiles.join(', '));
              }}

            />
            
            <div className='w-full' dangerouslySetInnerHTML={{ __html: response }} />
            
            <div className={'text-sm text-white flex w-full justify-center rounded-lg transition-all duration-500 overflow-hidden '+ visibleclassprogress } role="alert" >
              <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
              <span className="sr-only">Loading...</span>
            </div>
            </div>
            
            <div className={'bg-green-600 text-sm text-white w-full rounded-lg transition-all duration-500 overflow-hidden ' + visibleclassfinish } role="alert">
              <b>{getLocalizedString('uploadComplete', currentLanguage)}</b>
            </div>

            <div  className='w-full'>
              <ConfirmationNeokey key={neokey} item={neokey} openDialog={openDialog} />
              <div id='neokey' className="container mx-auto w-full space-y-5">
              </div>
            </div>

        </div>
      </div>

      <div className='pb-4'>
     </div>

      <div className="footer w-full flex absolute bottom-0 flex-col justify-between self-stretch pt-[var(--Utilities-Height-5,] pb-[var(--Navigations-Sidebar-Footer-Spacing-X,] pl-[20px)] pr-[20px)] p-5 border-t border-t-[#000]/[.10] dark:border-t-[#ffffff]/[.20] dark:bg-slate-900">
        
        <div className="">
          <div className="">
            <div className="flex gap-x-6 justify-between ">

            {options.map((option) => (
                <div className="flex" key={option.value}>
                  <input
                    type="radio"
                    id={option.value}
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                    name="mode"
                    className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                  />
                  <label htmlFor={option.value} className="text-sm text-gray-500 ms-2 dark:text-neutral-400" >{option.label}</label>
                </div>
              ))}


              <div className="flex hs-tooltip inline-block [--placement:right]">
                <button type="button" className="hs-tooltip-toggle inline-flex justify-center items-center  rounded-full text-white shadow-md shadow-gray-800/10 hover:bg-gray-800  hover:text-blue-600 hover:shadow-gray-800/10 transition">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12 16V12" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12 8H12.01" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">
                  <div className='text-left' dangerouslySetInnerHTML={{ __html: getLocalizedString('tooltipContent', currentLanguage) }} >
                    
                    </div>
                  </span>
                </button>
              </div>

            </div>

          </div>
        </div>

        <div className="dark:border-gray-700 border-gray-200 border-t">
          <div className="flex gap-4 mt-6 justify-between">
            
          <div className="_input flex flex-col flex-grow items-start self-stretch">
              <div className="relative input-1 transition-all  flex items-center self-stretch rounded-lg border border-gray-200 dark:bg-slate-900">
                <div className="absolute leading flex items-center pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                  <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12.6666 14.5V13.1667C12.6666 12.4594 12.3857 11.7811 11.8856 11.281C11.3855 10.781 10.7072 10.5 9.99998 10.5H5.99998C5.29274 10.5 4.61446 10.781 4.11436 11.281C3.61426 11.7811 3.33331 12.4594 3.33331 13.1667V14.5" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M7.99998 7.83333C9.47274 7.83333 10.6666 6.63943 10.6666 5.16667C10.6666 3.69391 9.47274 2.5 7.99998 2.5C6.52722 2.5 5.33331 3.69391 5.33331 5.16667C5.33331 6.63943 6.52722 7.83333 7.99998 7.83333Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                    <input className="bg-transparent pl-[50px] py-3 px-4 block w-full transition-all border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                    placeholder={getLocalizedString('caseId', currentLanguage)}
                    name="caseid" 
                    defaultValue={caseid}
                    id="caseid"
                    disabled={disabled}
                    onChange ={onChange}
                    required  
                    />
              </div>
            </div>
            
            {reverse &&  ( 

            <div className="_input flex flex-col items-start self-stretch w-1/2">

              <div className="relative input-1 flex items-center self-stretch rounded-lg border border-gray-700 dark:bg-slate-900">
                <div className="absolute leading flex items-center pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                  <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12.6666 14.5V13.1667C12.6666 12.4594 12.3857 11.7811 11.8856 11.281C11.3855 10.781 10.7072 10.5 9.99998 10.5H5.99998C5.29274 10.5 4.61446 10.781 4.11436 11.281C3.61426 11.7811 3.33331 12.4594 3.33331 13.1667V14.5" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M7.99998 7.83333C9.47274 7.83333 10.6666 6.63943 10.6666 5.16667C10.6666 3.69391 9.47274 2.5 7.99998 2.5C6.52722 2.5 5.33331 3.69391 5.33331 5.16667C5.33331 6.63943 6.52722 7.83333 7.99998 7.83333Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <input className="bg-transparent pl-[50px] py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                    placeholder={getLocalizedString('neoKeyreq', currentLanguage)}
                    name="neokey" 
                    required  
                />
              </div>
            </div>
            )
          }

          </div>
          <div className="mt-4">
            <div className="flex items-start self-stretch">
            <button type="submit" id="caseSubmit" className="_button text-white flex justify-center items-center pt-[var(--Components-Button-Spacing-Y,] pb-[var(--Components-Button-Spacing-X,] pl-[16px)] pr-[14px)] py-3 px-4 rounded-lg bg-blue-500 placeholder dark:text-white text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal] transition-opacity duration-300 disabled:opacity-50">
                {getLocalizedString('start', currentLanguage)}  { fileList.length >0 && ( <>von {fileList.length} Datei(en)</> )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div className="form  rounded-xl dark:bg-slate-900 bg-white p-5 xl:ml-10 mt-10 xl:mt-0 shrink-0 overflow-visible   w-[550px]" >
    
    { groupedFiles &&
        groupedFiles
          .filter((item: DocType) => !item.doc_name.startsWith("COMPOUND"))
          .map((item: DocType) => (

        <div key={item.doc_id} data-id={item.doc_id} className="flex flex-col  w-full mb-2 pt-[var(--Utilities-Spacing-3,] pb-[var(--Utilities-Spacing-3,] pl-[12px)] pr-[12px)] p-3 rounded-md border dark:border-gray-700 border-gray-200 dark:bg-slate-900">
                   
          <div className="_file-uploading-progress-form flex flex-col  ">
            <div className="file_progress_content flex items-center ">
              <div className="w-[26px]">
                <img className='' src={item.doc_name.split('.').pop() +'.png'} width="26" alt="" />
              </div>

              <div className="label flex flex-col items-start w-[260px] group relative " >
                <span className="absolute top-[-30px] left-[-7px] opacity-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:opacity-100">{item.doc_name} </span>
                <div  className="label-1  text-sm overflow-hidden dark:text-gray-200 text-gray-800 whitespace-no-wrap font-['Inter'] font-semibold leading-6">{splitString(item.doc_name)}</div>
                <div className="secondary_text  text-gray-400 font-['Inter'] text-xs font-medium">{Math.round(item.fsize/1000)} KB </div>
              </div>
              <div>
      
          </div>
              <div className="flex items-center gap-2.5" >
              {item.status === 0 && (    
                <div className="relative group">     
              <svg data-status={item.status} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>0</title>
                  <g clipPath="url(#clip0_604_2575)">
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8 4V8L10.6667 9.33333" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_604_2575">
                      <rect width={16} height={16} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="absolute hidden whitespace-nowrap group-hover:inline-block bg-gray-800 text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
                {getLocalizedString('status0', currentLanguage)}
                </span>
                </div>
              )}
              {item.status === 99 && (   
              <div className="relative group">       
              <svg data-status={item.status}  width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <g clipPath="url(#clip0_604_2575)">
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                    <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8 4V8L10.6667 9.33333" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_604_2575">
                      <rect width={16} height={16} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="absolute hidden whitespace-nowrap group-hover:inline-block bg-gray-800 text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
                {getLocalizedString('status99', currentLanguage)}
                </span>
                </div>
              )}
              {item.status === 2 && (    
                <div className="relative group">      
                <svg data-status={item.status} width={16} height={16} className="animate-spin" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
 
                    <g clipPath="url(#clip0_604_11076)">
                      <path className='stroke-[#E5E7EB] dark:stroke-[#4B5563]' d="M8 1.33337V4.00004" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8 12V14.6667" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M3.28662 3.28662L5.17329 5.17329" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M10.8267 10.8267L12.7133 12.7133" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M1.3335 8H4.00016" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12 8H14.6667" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M3.28662 12.7133L5.17329 10.8267" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                      <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M10.8267 5.17329L12.7133 3.28662" stroke="#E5E7EB" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_604_11076">
                        <rect width={16} height={16} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="absolute hidden whitespace-nowrap group-hover:inline-block bg-gray-800 text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
                  {getLocalizedString('status2', currentLanguage)}
                </span>
                </div>
                )}
                {item.status === 3 && ( 
                  <div className="relative group"> 
                <svg  data-status={item.status} width={16} height={16}  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <g clipPath="url(#clip0_482_29671)">
                    <path  d="M8.00016 14.6667C11.6822 14.6667 14.6668 11.682 14.6668 8.00004C14.6668 4.31804 11.6822 1.33337 8.00016 1.33337C4.31816 1.33337 1.3335 4.31804 1.3335 8.00004C1.3335 11.682 4.31816 14.6667 8.00016 14.6667Z" stroke="#14B8A6" strokeLinecap="round" strokeLinejoin="round" />
                    <path  d="M6 7.99996L7.33333 9.33329L10 6.66663" stroke="#14B8A6" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_482_29671">
                      <rect width={16} height={16} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                  <span className="absolute whitespace-nowrap hidden group-hover:inline-block bg-gray-800 text-white text-xs rounded py-1 px-2 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
                  {getLocalizedString('status3', currentLanguage)}
                </span>
                </div>
              )}

              <div className='confirm'><ConfirmationDialog item={item.doc_id} name={item.doc_name} /></div>
                
              {item.download_link && item.download_link != null &&( 
                <a href={`${DOWNLOAD_URL}${item.download_link}`} download={item.doc_id} rel="noreferrer" target='_blank'>
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M4.6665 6.66663L7.99984 9.99996L11.3332 6.66663" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M8 10V2" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                </svg></a>
                )} 
              </div>
            </div>
          </div>
        </div>
      ))
    }
  </div>

</div>
</>
)

};

export default Dropzone;

function splitString(string:any) {
  if (string.length <= 38) {
    return string;
  } else {
    const firstPart = string.slice(0,19);
    const lastPart = string.slice(-19);
    return firstPart + " ... " + lastPart;
  }
}