import LoginForm from '../components/LoginForm';

const Home = () => {


  return(

    <div className="content flex justify-center items-center self-stretch p-10">
      <LoginForm />
    </div>
    
    )

  
};

export default Home;