// UserContext.tsx
import React, { createContext, useState, ReactNode, useMemo, useEffect } from 'react';

interface UserData {
  payload: any;
  loggedin: boolean;
  error: string | null;
  errorcode: number | null;
  msgclear: boolean;
  // Add more properties as needed
}

interface UserContextValue {
  UserData: UserData | null;
  updateUserData: (data: Partial<UserData>) => void;
}

const initialUserData: UserData = {
  payload: null,
  loggedin: false,
  error: null,
  errorcode: null,
  msgclear: false,
};





export const UserContext = createContext<UserContextValue>({
  UserData: null,
  updateUserData: () => {},
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [UserData, setUserData] = useState<UserData>(() => {
    const storedData = sessionStorage.getItem('userData');
    return storedData ? JSON.parse(storedData) : initialUserData;
  });

  const updateUserData = (data: Partial<UserData>) => {
    setUserData(prevData => ({ ...prevData, ...data }));
  };

  const clearUserData = () => {
    setUserData(initialUserData);
  };
  

  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(UserData));
  }, [UserData]);

  const contextValue = useMemo(
    () => ({
      UserData,
      updateUserData,
      clearUserData,
    }),
    [UserData]
  );

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};