
interface dataObj {
    data: {
      [key: string]: {
        how: string;
        boiledEgg: string;
        softBoiledEgg: string;
        choice: string;
      };
    };
  }


const dataObj = {
    default:{
        how:"How do you want your egg today?",
        boiledEgg:"Boiled egg",
        softBoiledEgg:"Soft-boiled egg",
        choice:"How to choose the egg"
    },
    en:{
      how:"How do you want your egg today?",
      boiledEgg:"Boiled egg",
      softBoiledEgg:"Soft-boiled egg",
      choice:"How to choose the egg"
    },
    de: {
      how:"Come vuoi ",
      boiledEgg:"Uovo sodo",
      softBoiledEgg:"Uovo alla coque",
      choice:"Come scegliere l'uovo"
    }
  }
  export {dataObj};