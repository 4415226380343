import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation} from 'react-router-dom';
import { UserContext } from '../User';
import deLocale from '../de.json';
import { useNavigate, Link } from 'react-router-dom';

interface ConfirmationNewCaseProps {
  item?: string;
  openDialog?: boolean;
}

interface LocalizationData {
  [key: string]: string;
}

const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

const ConfirmationNewCase: React.FC<ConfirmationNewCaseProps> = ({ item , openDialog}) => {
 
  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const ToastMessage: React.FC<{ caseId: string }> = ({ caseId }) => (
    <div className='bg-red-500 rounded-xl p-4 my-4'>
      Dieser Vorgang existiert bereits. Wechseln Sie {' '}
      <Link to={`/Case`} state={{ caseId }} onClick={() => setShowNewCase(false)}><u>direkt zum Vorgang</u></Link> oder geben Sie einen anderen Namen ein.
    </div>
  );

  const navigate = useNavigate();

  const currentLanguage = 'de';

  const [showNewCase, setShowNewCase] = useState(openDialog);
  const [inputValue, setInputValue] = useState('');
  const [toastValidation, setToastValidation] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [caseId, setCaseid] = useState('');
  const [compound_mode, setCompoundMode] = useState('0');
  const [isChecked, setIsChecked] = useState(true);
  const [isValid, setIsValid] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    setShowNewCase(openDialog);
  }, [openDialog]);

  useEffect(() => {
    setDisabled(true)
    if(isValid){
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [isValid]);

  useEffect(() => {
    if(isChecked){
      setCompoundMode('1')
    }else{
      setCompoundMode('0')
    }
    console.log(isChecked)
    console.log(compound_mode)

}, [isChecked]);

  const closeNewCase = () => {
    setShowNewCase(false);
  };

  const validateInput = (value: string): boolean => {
    // Example validation: Check if input is not empty and has at least 3 characters
    return value.trim() !== '' && value.length >= 1;
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsValid(validateInput(event.target.value));
    //alert(isValid)
    if(isValid){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
  };

  const newCase = (inputValue: string) => () => {
    const caseId = inputRef.current?.value || '';
    console.log(caseId);
    setCaseid(caseId)
    let caseNames: string[] = [];

    try {
      const storedCaseNames = sessionStorage.getItem('caseNames');
      if (storedCaseNames) {
        caseNames = JSON.parse(storedCaseNames);
      } else {
        console.log("No case names found in sessionStorage");
      }
    } catch (error) {
      console.error("Error parsing case names from sessionStorage:", error);
    }
    
    if (caseNames.includes(caseId)) {
      setShowToast(true);
      sessionStorage.setItem('caseid', caseId);
    } else {
      setShowToast(false);
      sessionStorage.setItem('caseid', caseId);
      setShowNewCase(false);
      navigate('/Case', { state: { caseId, compound_mode} });
    }

  }
  
  return (
    <>
    <button type="button" onClick={() => setShowNewCase(true)} id="addCase" className="mr-4 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
        <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.91663 7H11.0833" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 2.9165V11.0832" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <div className="placeholder text-white text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal]">{getLocalizedString('newCase', currentLanguage)}</div>
    </button>
      
      {showNewCase && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <div className="inline-block align-bottom bg-white dark:bg-slate-900 dark:text-gray-200 text-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-slate-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 mb-8 font-medium text-center dark:text-gray-200 text-gray-800 mb-2" id="modal-title">
                    {getLocalizedString('newCaseModal', currentLanguage)}
                    </h3>
                    {showToast && <ToastMessage caseId={caseId} />}
                    <input className="bg-transparent pl-[10px] py-3 px-4 block w-full transition-all border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                        placeholder={getLocalizedString('caseId', currentLanguage)}
                        value={inputValue}
                        onChange={handleInputChange}
                        name="caseid" 
                        id="caseid"
                        ref={inputRef}
                        required
                    />
                <div className="sm:flex sm:items-start mt-8 mb-5">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center ">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V5C2 3.9 2.9 3 4 3H7.93C8.25941 3.0017 8.58331 3.08475 8.8729 3.24176C9.1625 3.39877 9.40882 3.62488 9.59 3.9L10.41 5.1C10.5912 5.37512 10.8375 5.60123 11.1271 5.75824C11.4167 5.91525 11.7406 5.9983 12.07 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V10.5"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21 17H15C14.4477 17 14 17.4477 14 18V21C14 21.5523 14.4477 22 15 22H21C21.5523 22 22 21.5523 22 21V18C22 17.4477 21.5523 17 21 17Z"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 17V15C20 14.4696 19.7893 13.9609 19.4142 13.5858C19.0391 13.2107 18.5304 13 18 13C17.4696 13 16.9609 13.2107 16.5858 13.5858C16.2107 13.9609 16 14.4696 16 15V17"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4  sm:mr-4 sm:text-left">

                    <div className="">

                      <p className="text-sm dark:text-gray-200 text-gray-800">
                      <b>{getLocalizedString('hcaseMode', currentLanguage)}</b>
                      <br/>
                      {getLocalizedString('expCasemode', currentLanguage)} 
                      </p>
                    </div>
                  </div>
                  

                <label className="inline-flex items-center cursor-pointer self-center">
                    <input 
                      type="checkbox" 
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="sr-only peer" />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>

                </div>
              </div>
              <div className="dark:bg-gray-800 bg-gray-200 text-gray-400 p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={newCase('')}
                  key={item}
                  disabled={disabled}
                  type="button"
                  className="mr-4 ml-4 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                >
                  {getLocalizedString('newCaseModal', currentLanguage)}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeNewCase}
                >
                  {getLocalizedString('close', currentLanguage)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationNewCase;