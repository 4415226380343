import { useLocation } from 'react-router-dom';
import Dropzone from '../components/Case';
import { useNavigate } from 'react-router-dom';
import deLocale from '../de.json';
import {  useContext ,useEffect } from 'react';
import { UserContext } from '../User';

interface LocalizationData {
  [key: string]: string;
}


export default function Case() {
 
  const location = useLocation();
  const {state} = location;
  const navigate = useNavigate();
  
  if (location.state) {
    const { title, jobId , casefiles, res} = state;
  } else {
    navigate('/');
  }

  useEffect(() => {
    if(!state){
      navigate('/');
    }
  }, []);
  
  return (
    <>

      {state === null ? (
           <></>
      ) : (
        <Dropzone casefiles={state.casefiles} case_id={state.title} res={state.res}/>
      )}
      
    </>
  );
}

