import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../User';
import ModeSwitcher from '../components/ModeSwitcher';
//import LanguageSwitch from './components/languageSwitch';
import deLocale from '../de.json';

interface LocalizationData {
  [key: string]: string;
}

interface OriginalObject {
  [key: number]: {
      error_status: number; doc_id: string; status: number; word_count: any; doc_name: string; download_link: any; error_msg: string; priority: number; case_id: string; job_id: string; created: string; last_update: string; start_processing: string; finished_processing: string; collected: string;
  };
}

function Header() {

 const API_URL = (window as any).API_URL;
 const NEOTOKEN = (window as any).NEOTOKEN;

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';

  const location = useLocation();
  const [response, setResponse] = useState("");
  const [loggedin, setLoggedin] = useState(false);
  const navigate = useNavigate();

  const { UserData, updateUserData } = useContext(UserContext);
  
  let avatar = "";
  let personname = "";
  let orgname = "";
  let tokenHash = "";
  let userid = "";

  if(UserData){

    tokenHash = UserData.payload ? UserData.payload.token : undefined;
    userid = UserData.payload ? UserData.payload.hashid :  undefined;
    personname = UserData.payload ? UserData.payload.name : undefined;
    orgname = UserData.payload ? UserData.payload.orgname:  undefined;

    if(personname && orgname) {
      avatar = personname.split(" ").map((n: string)=>n[0]).join("");
    }

  }

  useEffect(() => {

    if(UserData?.payload && sessionStorage.getItem('userData')){
      setLoggedin(true)
    }else{
      setLoggedin(false)
    }

  }, [UserData, loggedin]);

  function logoutCall() {

      let formData = new FormData()
      if (userid && tokenHash) {

      formData.append("userid", userid );
      formData.append("token_hash", tokenHash);
      formData.append("neotoken", NEOTOKEN);

      const apiurl = API_URL+'logout';
      // Using Fetch API
      fetch(apiurl, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
            setLoggedin(false)
            updateUserData({
                payload: null,
                loggedin: false,
                error: null,
                errorcode: null,
                msgclear: false,
              })
            sessionStorage.removeItem('userData');
            navigate('/', { replace: true });
        })
        .catch((err) => {
            console.log(err.message);
            setResponse('<div class="error">Processing your request was not possible. Please try again</div>');
        });
      } else {
        setLoggedin(false)
        updateUserData({
            payload: null,
            loggedin: false,
            error: null,
            errorcode: null,
            msgclear: false,
          })
        sessionStorage.removeItem('userData');
        navigate('/', { replace: true });
      }
    }

  return (

      <header>
        <div className="py-[20px] px-[50px] bg-black dark:bg-black text-white dark:text-white border-solid border-0  border-gray-500">
          <div className="flex flex-wrap gap-x-4 justify-between items-center">
            
            <div className='text-xl'> 
             <img className="block" src="\NL-NeoLaw-Logo-Horiz-White@2x.png" width='120px' />
              {/* <img className="hidden dark:block" src="\NL-NeoLaw-Logo-Horiz-White@2x.png" width='150px' />
              <img className="block dark:hidden" src="\NL-NeoLaw-Logo-Horiz-Black@2x.png" width='150px' /> */}
            </div>
            <div className="flex flex-wrap gap-x-4 justify-between items-center">
              
              {loggedin  &&(
                <>
                  <div className="flex items-start">
                    <div className="flex items-start">
                      <div className="_avatar-small mr-2 flex justify-center items-center w-[var(--Components-Avatar-Avatar-SM, h-[var(--Components-Avatar-Avatar-SM, rounded-full border border-gray-700 bg-gray-800 placeholder-2 text-gray-200 text-center font-['Inter'] text-sm font-semibold leading-5">
                     { avatar}
                      </div>
                    </div>
                    <div className="">
                      <div className="max_mustermann font-['Inter'] text-sm font-semibold leading-5">{personname}</div>
                      <div className="musterfirma text-gray-400 font-['Inter'] text-xs">{orgname}</div>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="_button-small flex justify-center items-center pt-[var(--Components-Button-Spacing-Y-SM,] pb-[var(--Components-Button-Spacing-X-SM,] pl-[12px)] pr-[10px)] px-3 rounded-lg">
                      <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.75 1.75H11.0833C11.3928 1.75 11.6895 1.87292 11.9083 2.09171C12.1271 2.3105 12.25 2.60725 12.25 2.91667V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H8.75" stroke="white" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.8335 9.91671L8.75016 7.00004L5.8335 4.08337" stroke="white" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.75 7H1.75" stroke="white" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <div id="logout" onClick={logoutCall} className=" cursor-pointer placeholder dark:text-white/[.80] text-white/[.80] text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal]">{getLocalizedString('logout', currentLanguage)}</div>
                    </div>
                  </div>
                  </>
              )}
              
              <ModeSwitcher />
              {/* <LanguageSwitch /> */}

            </div>
          </div>
        </div>
      </header>

  );
}

export default Header;
