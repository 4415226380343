import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import deLocale from '../de.json';
import { UserContext } from '../User';

interface LocalizationData {
  [key: string]: string;
}

const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

const LoginForm = () => {

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'en' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const { UserData, updateUserData } = useContext(UserContext);

  const currentLanguage = 'de';

  const [response, setResponse] = useState("");
  const [userid, setUserId] = useState("");
  const [token_hash, setToken] = useState("");
  const [loggedin, setLoggedin] = useState(false);


  const navigate = useNavigate();

  const tokenHash = localStorage.getItem("token_hash");
  const user_id = localStorage.getItem("userid");

  function handleSubmit(e: React.FormEvent) {

    setResponse('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    if (e.target instanceof HTMLFormElement) {
      const formData = new FormData(e.target);
      //console.log(formData);
      const apiurl = API_URL+'login';
      // Using Fetch API
      fetch(apiurl, {
        method: 'POST',
        body: formData,
        headers: {
          //'Content-type': 'multipart/form-data; charset=UTF-8',
        },
      })
        .then((res) => res.text())
        .then((data) => {
            //console.log(data);
            let dataJson = JSON.parse(data)

            setUserId('');
            setToken('');
            updateUserData(dataJson);
            
            if(dataJson.errorcode === 2 ){
              localStorage.setItem("userid", dataJson.payload.hashid);

          
              const pin =  formData.get('pin')

              navigate('/changepw', { state: pin });
            }

            if( dataJson.errorcode >0 && dataJson.errorcode !== 12){
              setResponse(
                '<div class="my-3 bg-red-500 text-sm text-white rounded-lg p-4" role="alert">'+
                  getLocalizedString('loginIncorrect', currentLanguage)+
                '</div>');
            }

            if( dataJson.errorcode === 0 || dataJson.errorcode === 12){
              navigate('/Dashboard');
            } 

        })
        .catch((err) => {
            console.log(err.message);
            setResponse('<div class="error">processing your request was not possible. Please try again</div>');
        });
      }
    }

    useEffect(() => {
      if(tokenHash && user_id){
        navigate('/Dashboard');
      }else{

        //setLoggedin(false);
        //navigate('/');
      }
    }, [user_id, tokenHash, UserData]);
    
    

  return(
    <form className='neo-form' id="login" onSubmit={handleSubmit} encType="multipart/form-data">
      <input className="bg-transparent border-0 w-100" name="neotoken" type="hidden" value={NEOTOKEN} />
      <div className="form flex flex-col items-start pt-[var(--Utilities-Spacing-9,] pb-[var(--Utilities-Spacing-9,] pl-[36px)] pr-[36px)] p-9 w-[432px] rounded-xl dark:bg-slate-900">
        <div className="sign_in self-stretch dark:text-gray-200 text-center font-['Inter'] text-2xl font-bold leading-[normal]">      {getLocalizedString('signIn', currentLanguage)}</div>
        <div className="layout_group flex flex-col items-start self-stretch">
        <div className='w-full' dangerouslySetInnerHTML={{ __html: response }} />
            
            {!loggedin && (
              <>
              <div className="input_group flex flex-col items-start self-stretch">
            <div className="flex flex-col items-start self-stretch">
              <div className="_input flex flex-col items-start self-stretch">
                <div className="flex items-start self-stretch label-1 dark:text-gray-200 font-['Inter'] text-sm font-semibold leading-[normal]">
                {getLocalizedString('emailAddress', currentLanguage)}
                </div>
                <div className="relative input-1 flex items-center self-stretch rounded-lg border dark:border-gray-700 dark:bg-slate-900">
                  <div className="absolute leading flex items-center pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.6666 14.5V13.1667C12.6666 12.4594 12.3857 11.7811 11.8856 11.281C11.3855 10.781 10.7072 10.5 9.99998 10.5H5.99998C5.29274 10.5 4.61446 10.781 4.11436 11.281C3.61426 11.7811 3.33331 12.4594 3.33331 13.1667V14.5" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.99998 7.83333C9.47274 7.83333 10.6666 6.63943 10.6666 5.16667C10.6666 3.69391 9.47274 2.5 7.99998 2.5C6.52722 2.5 5.33331 3.69391 5.33331 5.16667C5.33331 6.63943 6.52722 7.83333 7.99998 7.83333Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                      <input className="bg-transparent pl-[50px] py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" name="username" required  />
                </div>
              </div>
            </div>

            <div className="w-full  bg-transparent rounded-lg">
              <label className="block text-sm mb-2 text-sm font-semibold">{getLocalizedString('password', currentLanguage)}</label>
              <div className="relative border rounded-lg dark:border-gray-700">
              <div className="leading-1 absolute pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.99998 14.5003C7.02502 14.5003 8.66665 12.8587 8.66665 10.8337C8.66665 8.80861 7.02502 7.16699 4.99998 7.16699C2.97494 7.16699 1.33331 8.80861 1.33331 10.8337C1.33331 12.8587 2.97494 14.5003 4.99998 14.5003Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14 1.83301L7.59998 8.23301" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.3333 5.50033L12.3333 7.50033L14.6666 5.16699L12.6666 3.16699" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <input 
                  id="hs-toggle-password" 
                  name="pin" type="password" 
                  className="bg-transparent pl-[50px] py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                  />
                <button type="button" data-hs-toggle-password='{
                  "target": "#hs-toggle-password"
                }' className="absolute top-0 end-0 p-3.5 rounded-e-md">
                  <svg className="flex-shrink-0 size-3.5 dark:text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                    <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                    <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                    <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                    <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                    <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
              </div>
              <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2.5 self-stretch">
            <div className="flex items-start self-stretch">
              <button type="submit" className="_button flex justify-center items-center pt-[var(--Components-Button-Spacing-Y,] pb-[var(--Components-Button-Spacing-X,] pl-[16px)] pr-[14px)] py-3 px-4 rounded-lg bg-blue-500 placeholder text-white dark:text-white text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal]">
              {getLocalizedString('login', currentLanguage)}
              </button>
            </div>
          </div>
          </>
          )

        }

        </div>
      </div>
    </form>
  );
}

export default LoginForm;
