import React from 'react';
import { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useNavigate } from 'react-router-dom';
import { dataObj} from '../locale';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import deLocale from '../de.json';

let locale = new LocalizedStrings({dataObj});

interface PwProps {
  formValue?: any;
}

interface LocalizationData {
  [key: string]: string;
}

const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;


const LoginForm = ({formValue}:PwProps) => {


  if(formValue){
    const cred = formValue.location.state;
    console.log(cred)
  }

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'en' ? deLocale : deLocale;
    return localeData[id] || id;
  };
  const currentLanguage = 'de';

  const password = document.getElementById("password") as HTMLInputElement | null;
  const confirm_password = document.getElementById("confirm_password") as HTMLInputElement | null;
  const submit = document.getElementById("submit") as HTMLInputElement | null;

  const [response, setResponse] = useState("");
  const [pin, setPin] = useState("");

  function validatePassword() {

    if (password?.value !== confirm_password?.value) {
      confirm_password?.setCustomValidity("Passwords Don't Match");
      setResponse(
        '<div class="my-3 w-full bg-red-500 text-sm text-white rounded-lg p-4" role="alert">'+
          'Passwörter stimmen nicht überein' +
        '</div>');
      if (submit) {
        submit.disabled = true;
      }
    } else {
      confirm_password?.setCustomValidity('');
      setResponse('');
      if (submit) {
        submit.disabled = false;
      }
    }
  };


  const route = useLocation();
  const state = route.state;
  const userid = localStorage.getItem('userid')


  useEffect(() => {
    console.log(state)
    if(state){
      setPin(state);
    } else{
      setPin('')
    }
  }, [state]);

  const navigate = useNavigate();

  password?.addEventListener('input', validatePassword);
  confirm_password?.addEventListener('input', validatePassword);
  submit?.addEventListener('click', validatePassword);

  function handleSubmit(e: React.FormEvent) {
    
    e.preventDefault();

    validatePassword();

    console.log(pin)

    if (e.target instanceof HTMLFormElement) {
      const formData = new FormData(e.target);
      
      if(userid){
        formData.append("userid", userid);
      }
      if(pin !==''){
        formData.append("pin", pin);
      }
      
      console.log(formData);

      const apiurl = API_URL+'changepin';
      // Using Fetch API
      fetch(apiurl, {
        method: 'POST',
        body: formData,
        headers: {
          //'Content-type': 'multipart/form-data; charset=UTF-8',
        },
      })
        .then((res) => res.text())
        .then((data) => {
            console.log(data);
            let dataJson = JSON.parse(data)

            if( JSON.parse(data).msgclear === 'CRITERIA NOT MET'){
              setResponse(
              '<div class="my-3 w-full bg-red-500 text-sm text-white rounded-lg p-4" role="alert">'+
                getLocalizedString('criteriaNotMet', currentLanguage) +
              '</div>');
            }

            //"errorcode":2,"status":1,
            if(dataJson.errorcode === 0 || dataJson.errorcode === 1 || dataJson.errorcode === 12){
              navigate('/', { replace: true });
            }
        })
        .catch((err) => {
            console.log(err.message);
            setResponse('<div class="mt-2 bg-red-500 text-sm text-white rounded-lg p-4" role="alert"><span class="font-bold">Danger</span> alert! You should check in on some of those fields below.</div>');
        });
      }
    }
    
  return(
    <div className="content flex justify-center items-center self-stretch p-10">
    <form className='neo-form' id="login" onSubmit={handleSubmit} encType="multipart/form-data">
      <input className="bg-transparent border-0 w-100" name="neotoken" type="hidden" value={NEOTOKEN }/>
      
      <div className="form flex flex-col items-start pt-[var(--Utilities-Spacing-9,] pb-[var(--Utilities-Spacing-9,] pl-[36px)] pr-[36px)] p-9 w-[432px] rounded-xl dark:bg-slate-900">
        <div className="sign_in self-stretch dark:text-gray-200  font-['Inter'] text-1xl font-bold leading-[normal]">Bitte ändern Sie Ihr Passwort</div>
        <div className="layout_group flex flex-col items-start self-stretch">
          <div className="input_group flex flex-col items-start self-stretch">
            <div className="flex flex-col items-start self-stretch">

            <div className='w-full' dangerouslySetInnerHTML={{ __html: response }} />

            <div className="w-full  bg-transparent rounded-lg py-3 mt-4">
              <label className="block text-sm mb-2 text-sm font-semibold">{getLocalizedString('password', currentLanguage)}</label>
              <div className="relative border rounded-lg dark:border-gray-700">
              <div className="leading-1 absolute pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.99998 14.5003C7.02502 14.5003 8.66665 12.8587 8.66665 10.8337C8.66665 8.80861 7.02502 7.16699 4.99998 7.16699C2.97494 7.16699 1.33331 8.80861 1.33331 10.8337C1.33331 12.8587 2.97494 14.5003 4.99998 14.5003Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14 1.83301L7.59998 8.23301" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.3333 5.50033L12.3333 7.50033L14.6666 5.16699L12.6666 3.16699" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <input 
                  id="password" 
                  name="password" type="password" 
                  className="bg-transparent pl-[50px] py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                  />
                <button type="button" data-hs-toggle-password='{
                  "target": "#password"
                }' className="absolute top-0 end-0 p-3.5 rounded-e-md">
                  <svg className="flex-shrink-0 size-3.5 dark:text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                    <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                    <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                    <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                    <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                    <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
              </div>
              <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
            </div>


            <div className="w-full  bg-transparent rounded-lg my-4">
              <label className="block text-sm mb-2 text-sm font-semibold">{getLocalizedString('confirmPassword', currentLanguage)}</label>
              <div className="relative border rounded-lg dark:border-gray-700">
              <div className="leading-1 absolute pt-[var(--basic-forms-input-leading-trailing-spacing-y,] pb-[var(--basic-forms-input-leading-trailing-spacing-x,] pl-[16px)] pr-[14px)] py-3 px-4">
                    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.99998 14.5003C7.02502 14.5003 8.66665 12.8587 8.66665 10.8337C8.66665 8.80861 7.02502 7.16699 4.99998 7.16699C2.97494 7.16699 1.33331 8.80861 1.33331 10.8337C1.33331 12.8587 2.97494 14.5003 4.99998 14.5003Z" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14 1.83301L7.59998 8.23301" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.3333 5.50033L12.3333 7.50033L14.6666 5.16699L12.6666 3.16699" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <input 
                  id="confirm_password" 
                  name="newpin" type="password" 
                  className="bg-transparent pl-[50px] py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" 
                  />
                <button type="button" data-hs-toggle-password='{
                  "target": "#confirm_password"
                }' className="absolute top-0 end-0 p-3.5 rounded-e-md">
                  <svg className="flex-shrink-0 size-3.5 dark:text-gray-400" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                    <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                    <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                    <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                    <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                    <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                  </svg>
                </button>
              </div>
              <p className="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
            </div>

          <div className="flex flex-col items-start gap-2.5 self-stretch mt-2">
            <div className="flex items-start self-stretch">
              <button id="submit" type="submit" className="_button flex justify-center items-center pt-[var(--Components-Button-Spacing-Y,] pb-[var(--Components-Button-Spacing-X,] pl-[16px)] pr-[14px)] py-3 px-4 rounded-lg bg-blue-500 placeholder dark:text-white text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal]">
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

      
    </form>
    </div>
  );
}

export default LoginForm;

