import React from 'react';
import { useNavigate } from 'react-router-dom';
import deLocale from '../de.json';
import DeleteCase  from './ConfirmationDeletecase';

interface LocalizationData {
  [key: string]: string;
}

interface DashboardItemProps {
  /** The text to display inside the button */
  title?: string;
  jobId?: string;
  casefiles?: any;
  countfiles?: any;
  lastUpdate?: any;
  compound_mode: number;
}

const DashboardItem = ({ title, casefiles, compound_mode, countfiles, lastUpdate }: DashboardItemProps) => {

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';
 
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/Case',{ state: { title, casefiles , compound_mode} });
  };

  function DateFormatter(props:any) {

    const date = new Date(props);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    //const seconds = String(date.getSeconds()).padStart(2, '0');
    let germanDateFormat = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return germanDateFormat;
  }

  return(

    <div key={title} onClick={handleClick} data-id={title} className="card-18 overflow-hidden border border-gray-200 cursor-pointer flex flex-col items-start w-[20.3125rem] rounded-xl  dark:border-gray-700 ">
      <div className="body-18 bg-white  flex flex-col items-start self-stretch pt-[var(--Components-Card-Spacing,] pb-[var(--Components-Card-Spacing,] pl-[20px)] pr-[20px)] p-5 dark:bg-slate-900">
        <div className="block w-full">
          <div className='justify-between footer-18 flex w-100 items-start self-stretch'>
            <div className="123456789-4 dark:text-gray-200 text-gray-800 font-['Inter'] font-bold leading-6">
              {title}
            </div>  
            <div>
              {compound_mode === 1 && (
                               <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M10 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V5C2 3.9 2.9 3 4 3H7.93C8.25941 3.0017 8.58331 3.08475 8.8729 3.24176C9.1625 3.39877 9.40882 3.62488 9.59 3.9L10.41 5.1C10.5912 5.37512 10.8375 5.60123 11.1271 5.75824C11.4167 5.91525 11.7406 5.9983 12.07 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V10.5"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                               <path d="M21 17H15C14.4477 17 14 17.4477 14 18V21C14 21.5523 14.4477 22 15 22H21C21.5523 22 22 21.5523 22 21V18C22 17.4477 21.5523 17 21 17Z"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                               <path d="M20 17V15C20 14.4696 19.7893 13.9609 19.4142 13.5858C19.0391 13.2107 18.5304 13 18 13C17.4696 13 16.9609 13.2107 16.5858 13.5858C16.2107 13.9609 16 14.4696 16 15V17"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                               </svg>   
              )}
              </div>
          </div>  
          <div className="flex flex-col items-start">
            <div className="025-05-25__16_13_43-18 text-gray-500 dark:text-gray-400 font-['Inter'] text-sm font-medium leading-5">{DateFormatter(lastUpdate.created)}</div>
            <div className="9999_files-18 text-gray-500 font-['Inter'] dark:text-gray-400  text-sm font-medium leading-5">{countfiles} {getLocalizedString('files', currentLanguage)}</div>
          </div>
        </div>
      </div>
      <div className="justify-between footer-18 flex items-start self-stretch pt-[var(--Components-Card-Footer-Spacing,] pb-[var(--Components-Card-Footer-Spacing,] pl-[20px)] pr-[20px)] p-5  bg-slate-50 dark:bg-gray-800 dark:text-gray-400   text-gray-500 font-['Inter'] text-sm font-medium leading-5">
      {getLocalizedString('lastUpdated', currentLanguage)} {DateFormatter(lastUpdate.lastUpdated)}
      <DeleteCase item={title} casefiles={casefiles} />
      </div>
    </div>
 

  )
};

export default DashboardItem;