import React, { useState, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import { UserContext } from '../User';
import deLocale from '../de.json';

interface ConfirmationDialogProps {
  item?: string;
  name?: string;
  states?: any;
}
const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

interface LocalizationData {
  [key: string]: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ item, name }) => {

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';

  const { UserData, updateUserData } = useContext(UserContext);

  let orgaid = "";
  let tokenHash = "";
  let userid = "";
  let licence = ""

  if(UserData){

    tokenHash = UserData.payload ? UserData.payload.token : undefined;
    userid = UserData.payload ? UserData.payload.hashid :  undefined;
    licence = UserData.payload ? UserData.payload.licence:  undefined;
    orgaid = UserData.payload ? UserData.payload.org:  undefined;

  }

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');


  let openDialog = (item: string) => {
    setCurrentItem(item);
    //setCurrentName(name);
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  function deleteItem(doc_id:string) {



    const formData = new FormData();
   
    if (tokenHash && userid && orgaid) {
      formData.append("itemid", doc_id)
      formData.append("userid", userid );
      formData.append("token_hash", tokenHash);
      formData.append("orgaid", orgaid);
      if (licence !== null) {
        formData.append("licence", licence);
      }
      formData.append("neotoken", NEOTOKEN);
    }

    const apiurl = API_URL+'deleteitem';

    fetch(apiurl, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.text())
      .then((data) => {
          console.log(data);
          if(data){
            let dataJson = JSON.parse(data)
              console.log(dataJson.msgclear)
              let element = document.body.querySelector('[data-id="'+doc_id+'"]') as HTMLElement | null;
              if (element) {
                element.style.display = 'none';
              }
          }

      })
      .catch((err) => {
          console.log(err.message);
      });
    
  }


  return (
    <>

        <div
          key={item}
          
          onClick={() => openDialog(currentItem)}
        >
                <svg width={16} height={16} viewBox="0 0 16 16" className="cursor-pointer"  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M2 4H14" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M12.6668 4V13.3333C12.6668 14 12.0002 14.6667 11.3335 14.6667H4.66683C4.00016 14.6667 3.3335 14 3.3335 13.3333V4" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M5.3335 4.00004V2.66671C5.3335 2.00004 6.00016 1.33337 6.66683 1.33337H9.3335C10.0002 1.33337 10.6668 2.00004 10.6668 2.66671V4.00004" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M6.6665 7.33337V11.3334" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                  <path className='stroke-[#666] dark:stroke-[#E5E7EB]' d="M9.3335 7.33337V11.3334" stroke="#9CA3AF" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
        </div>


      {showDialog && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white dark:bg-slate-900 dark:text-gray-200 text-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-slate-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg hidden leading-6 font-medium dark:text-gray-200 text-gray-800 mb-2" id="modal-title">
                    
                    </h3>
                    <div className="">
                      <p className="text-sm dark:text-gray-200 text-gray-800">
                      {getLocalizedString('confirm_delete_doc1', currentLanguage)} <b>{name}</b> {getLocalizedString('confirm_delete_doc2', currentLanguage)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dark:bg-gray-800 bg-gray-200 text-gray-400 p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  key={item}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => deleteItem(item!)} 
                >
                  {getLocalizedString('delete', currentLanguage)}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeDialog}
                >
                  {getLocalizedString('dismiss', currentLanguage)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationDialog;