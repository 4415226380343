import React, { useState, useEffect } from 'react';

const ModeSwitcher = () => {
  
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const isDarkMode = sessionStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    sessionStorage.setItem('darkMode', darkMode.toString());
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (

    <div>
 {/*     <button type="button" 
      onClick={toggleDarkMode}
      className="hs-dark-mode-active:hidden block hs-dark-mode group flex items-center text-gray-400 hover:text-gray-200 font-medium dark:text-neutral-400 dark:hover:text-neutral-500" data-hs-theme-click-value="dark">
      <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" 
      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
      </svg>
    </button>
    <button type="button" 
      onClick={toggleDarkMode}
      className="hs-dark-mode-active:block hidden hs-dark-mode group flex items-center text-gray-200 hover:text-white font-medium dark:text-gray-200 dark:hover:text-white" data-hs-theme-click-value="light">
      <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" 
      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" 
      strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="4"></circle>
        <path d="M12 2v2"></path>
        <path d="M12 20v2"></path>
        <path d="m4.93 4.93 1.41 1.41"></path>
        <path d="m17.66 17.66 1.41 1.41"></path>
        <path d="M2 12h2"></path>
        <path d="M20 12h2"></path>
        <path d="m6.34 17.66-1.41 1.41"></path>
        <path d="m19.07 4.93-1.41 1.41"></path>
      </svg>
    </button>  */}


    <div className="relative inline-block">
    <input  onClick={toggleDarkMode} checked={darkMode} type="checkbox" id="hs-default-switch-with-icons" 
    className="peer focus:ring-0 focus:ring-offset-0 relative w-[3.25rem] h-7 p-px flex items-center
    bg-neutral-800 border-neutral-600 border-0
    text-transparent rounded-full cursor-pointer 
    transition-colors ease-in-out duration-200 ring-0 focus:ring-0 focus:checked:ring-0 disabled:opacity-50 
    disabled:pointer-events-none 
    checked:bg-none checked:bg-neutral-800 focus:checked:bg-neutral-800 checked:text-gray-600 checked:border-neutral-600  
    focus:checked:border-1 checked:border-1 focus:checked:border-1 dark:bg-neutral-800 focus:border-1
    dark:border-neutral-800  dark:checked:bg-neutral-800
    dark:checked:border-gray-500 checked:border-neutral-600 dark:checked:border-1
    dark:focus:ring-0 before:inline-block before:size-6 before:bg-white 
    checked:before:bg-gray-200 before:translate-x-0 checked:before:translate-x-full 
    before:rounded-full before:shadow before:transform before:ring-0 before:transition 
    before:ease-in-out before:duration-200 before:bg-neutral-100 
    checked:before:bg-gray-100 dark:before:bg-neutral-300 dark:checked:before:bg-neutral-200" />
    <label htmlFor="hs-default-switch-with-icons" className="sr-only">switch</label>
    <span className="peer-checked:text-gray-300 text-gray-600 size-6 absolute top-0.5 start-0.5 flex justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500">
    <svg className="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" 
      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" 
      strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="4"></circle>
        <path d="M12 2v2"></path>
        <path d="M12 20v2"></path>
        <path d="m4.93 4.93 1.41 1.41"></path>
        <path d="m17.66 17.66 1.41 1.41"></path>
        <path d="M2 12h2"></path>
        <path d="M20 12h2"></path>
        <path d="m6.34 17.66-1.41 1.41"></path>
        <path d="m19.07 4.93-1.41 1.41"></path>
      </svg>
    </span>
    <span className="peer-checked:text-gray-600 text-gray-300 size-6 absolute top-0.5 end-0.5 flex justify-center items-center pointer-events-none transition-colors ease-in-out duration-200 dark:text-neutral-500">
    <svg className="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" 
      width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
      </svg>
    </span>
  </div>



    </div>





  );
};

export default ModeSwitcher;
