import React from 'react';
import DashboardItem from '../components/DashboardItem'
import { useLocation} from 'react-router-dom';

interface LocalizationData {
  [key: string]: string;
}

interface DashboardCasesProps {
  cases: any;
}

type CaseType = {
  case_id: string | number;
};

interface DocumentObject {
  compound_mode: number | null;
  error_status: number;
  doc_id: string;
  status: number;
  word_count: number;
  doc_name: string;
  download_link: string;
  error_msg: string;
  priority: number;
  case_id: string;
  job_id: string;
  created: string;
  last_update: string;
  start_processing: string;
  finished_processing: string;
  collected: string;
  fsize: string | null;
  mode: number | null;
  id: string;
}

type FileObject = {
  compound_mode: number;
  error_status: number;
  doc_id: string;
  status: number;
  word_count: number;
  doc_name: string;
  download_link: string;
  error_msg: string;
  priority: number;
  case_id: string;
  job_id: string;
  created: string;
  last_update: string;
  start_processing: string;
  finished_processing: string;
  collected: string;
  fsize: string;
  mode: number;
  id: string;
};

// Define the type for casesArray
type CaseArray = [string, FileObject[]][];


const Dashboard = ({cases}:DashboardCasesProps) => {

  let casesArray = Object.entries(cases); 
  const route = useLocation();
  console.log(casesArray)
  
  function count(files: any[]): number{
    return files.length
  }

  function DateFormatter(props:any) {

    const date = new Date(props);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    //const seconds = String(date.getSeconds()).padStart(2, '0');
    let germanDateFormat = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return germanDateFormat;
  }



  function last_update(files: any[]): { created: string, lastUpdated: string } {
    if (files.length === 0) {
        return { created: '', lastUpdated: '' };
    }
    
    const sortedByCreated = [...files].sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const sortedByLastUpdate = [...files].sort((a, b) => new Date(b.last_update).getTime() - new Date(a.last_update).getTime());
    
    return {
        created: sortedByCreated[0].created,
        lastUpdated: sortedByLastUpdate[0].last_update
    };
}



const sortedCasesArray = casesArray.sort((a: any[], b: any[]) => {
    const dateA = new Date(last_update(a[1]).lastUpdated);
    const dateB = new Date(last_update(b[1]).lastUpdated);
    return dateB.getTime() - dateA.getTime(); // Descending order
});

  return(

  <div className="content flex flex-col items-start gap-2.5 self-stretch p-10">
    <div className="layout_group flex flex-wrap  items-start content-start gap-5 self-stretch">

      {
        sortedCasesArray.map((item:any) => (
          <DashboardItem title={item[0]}  casefiles={item[1]} compound_mode={item[1][0]['compound_mode']} countfiles={count(item[1])} lastUpdate={last_update(item[1]) } />
        ))
      }

    </div>

  </div>
  )

};

export default Dashboard;
