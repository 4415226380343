import React, { useState, useContext, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { UserContext } from '../User';
import deLocale from '../de.json';

interface ConfirmationNeokeyProps {
  item?: string;
  openDialog?: boolean;
}

interface LocalizationData {
  [key: string]: string;
}

const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

const ConfirmationNeokey: React.FC<ConfirmationNeokeyProps> = ({ item , openDialog}) => {
 
  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';

  const [showNeokey, setShowNeokey] = useState(openDialog);
  const [currentItem, setCurrentItem] = useState<string>('');

  useEffect(() => {
    setShowNeokey(openDialog);
  }, [openDialog]);

  const closeNeokey = () => {
    setShowNeokey(false);
  };

  function handleCopyClickFallback(neokeyvalue:any) {
    var textArea = document.createElement("textarea");
    textArea.value = neokeyvalue;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
    }
  
    document.body.removeChild(textArea);
  }
  
  const handleCopyClick = (item: string) => () => {
    if (!navigator.clipboard) {
      handleCopyClickFallback(item);
      return;
    }
    navigator.clipboard.writeText(item).then(function() {
  
    }, function(err) {
  
    });
  }
  
  return (
    <>

      {showNeokey && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white dark:bg-slate-900 dark:text-gray-200 text-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white dark:bg-slate-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg hidden leading-6 font-medium dark:text-gray-200 text-gray-800 mb-2" id="modal-title">
                    
                    </h3>
                    <div className="">
                      <p className="text-sm dark:text-gray-200 text-gray-800">
                      {getLocalizedString('neo_key_modal', currentLanguage)}
                      <br/>
                      {getLocalizedString('neoKey', currentLanguage)}: <span className="neokeyvalue">{item}</span> 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dark:bg-gray-800 bg-gray-200 text-gray-400 p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleCopyClick(item!)}
                  key={item}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {getLocalizedString('copy', currentLanguage)}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeNeokey}
                >
                  {getLocalizedString('close', currentLanguage)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationNeokey;