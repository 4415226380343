import Dashboard from '../components/Dashboard';
import JobNav from '../components/JobNav';
//import { Refresh } from '../functions/globalFunctions';
import { useEffect, useState, useContext } from 'react';
import { json, useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../User';

interface DashboardCasesProps {
  cases?: any;
  original_object?: any;
}

interface OriginalObject {
  [key: string]: {
      error_status: number; doc_id: string; status: number; word_count: any; doc_name: string; download_link: any; error_msg: string; priority: number; case_id: string; job_id: string; created: string; last_update: string; start_processing: string; finished_processing: string; collected: string;
  };
}

const API_URL = (window as any).API_URL;
const NEOTOKEN = (window as any).NEOTOKEN;

export default function Job({cases}:DashboardCasesProps) {

  const { UserData, updateUserData } = useContext(UserContext);

  let groupedCases = {}

  const route = useLocation();
  const location = useLocation();

  const [running, setRunning] = useState(Boolean);
  const [refreshData, setRefreshData] = useState('');
  const [state, setState] = useState(UserData!);

  let orgaid = "";
  let tokenHash = "";
  let userid = "";
  let licence = ""

  if(UserData){

    tokenHash = UserData.payload ? UserData.payload.token : undefined;
    userid = UserData.payload ? UserData.payload.hashid :  undefined;
    licence = UserData.payload ? UserData.payload.licence:  undefined;
    orgaid = UserData.payload ? UserData.payload.org:  undefined;

  }
  
  const navigate = useNavigate();

      useEffect(() => {

        if(NEOTOKEN && NEOTOKEN !==''){
          const formData = new FormData();
          if (licence !== null && tokenHash !== null && userid !== null) {
            formData.append("userid", userid );
            formData.append("token_hash", tokenHash);
            formData.append("licence", licence);
          }
          formData.append("neotoken", NEOTOKEN);
    
          const apiurl = API_URL+'refresh';
    
          if(running){
            //console.log(running)
          }else{
    
          setRunning(true)
          fetch(apiurl, {
            method: 'POST',
            body: formData,
          })
            .then((res) => res.text())
            .then((data) => {
                //console.log(data);
                if(data){
                  setRefreshData(data);
                  }
                  setRunning(false)

                  if(JSON.parse(data).errorcode !== 0 && JSON.parse(data).errorcode !== 12 ){
                    console.log(JSON.parse(data).msgclear);
                    updateUserData({})
                    navigate('/');
                  }

                }
            )
            .catch((err) => {
                console.log(err.message);
            });
        }
      }

        const interval = setInterval(() => {
    
        if(NEOTOKEN && NEOTOKEN !==''){
          const formData = new FormData();
          if (licence !== null && tokenHash !== null && userid !== null) {
            formData.append("userid", userid );
            formData.append("token_hash", tokenHash);
            formData.append("licence", licence);
          }
          formData.append("neotoken", NEOTOKEN);
    
          const apiurl = API_URL+'refresh';
    
          if(running){
            //console.log(running)
          }else{
    
          setRunning(true)
          fetch(apiurl, {
            method: 'POST',
            body: formData,
            headers: {
              //'Content-type': 'multipart/form-data',
            },
          })
            .then((res) => res.text())
            .then((data) => {
                //console.log(data);
                if(data){
                  setRefreshData(data)
                  if(JSON.parse(data).errorcode !== 0 && JSON.parse(data).errorcode !== 12 ){
                    console.log(JSON.parse(data).msgclear);
                    updateUserData({})
                    navigate('/');
                  }

                  }
                  setRunning(false)
                  //console.log(running)
                }
            )
            
            .catch((err) => {
                console.log(err.message);
            });
        }
  
    
        }
      }, 3000);
        
        return () => clearInterval(interval);

  }, [UserData]);

  if(refreshData && JSON.parse(refreshData).payload.queue){
    groupedCases = sortandgroup (JSON.parse(refreshData).payload.queue);
    const caseNames: string[] = Object.keys(groupedCases);
    console.log(caseNames)
    sessionStorage.setItem('caseNames' , JSON.stringify(caseNames))
  } else {
    //navigate('/');
  }

  return (
    <>
      <JobNav  res={state} />
      <Dashboard cases={groupedCases} />
    </>
  );
}

function sortandgroup(original_object: OriginalObject){

    var objects = Object.keys(original_object).reduce((arr: Array<any>, key:string) => {
      arr.push(Object.assign({}, original_object[key], { id: key }));
      return arr;
    }, []);

    var sorted_objects = objects.sort(function(a, b) {
      return a.position >= b.position ? 1 : -1;
    });

    var groups = sorted_objects.reduce(function(res, item) {
      res[item.case_id] = res[item.case_id] || [];
      res[item.case_id].push(item);
      return res;
    }, {});

    return groups;
}
