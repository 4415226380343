import React from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import deLocale from '../de.json';
import NewCase  from './ConfirmationNewCase';


interface LocalizationData {
  [key: string]: string;
}

interface JobProps {
  JobName?: string;
  compound_mode?: any;
  key?: any;
  res?: any;
}

const JobNav = ({JobName, compound_mode}:JobProps) => {

  const getLocalizedString = (id: string, lang: string): string => {
    const localeData: LocalizationData = lang === 'de' ? deLocale : deLocale;
    return localeData[id] || id;
  };

  const currentLanguage = 'de';

  const navigate = useNavigate();
  const [compoundMode, setCompoundMode] = useState(Boolean);
  const route = useLocation();
  const state = route.state;
  console.log(state);
  
  useEffect(() => {
    if(state && state.compound_mode === 1){
      setCompoundMode(true);
    }else{
      setCompoundMode(false);
    }
    console.log(compoundMode)
  }, [compoundMode]);
 
  
  return(
      <div className="flex justify-between px-[50px] items-center h-16 dark:bg-slate-900 bg-white dark:text-white">

            <div className="flex items-center h-16">
                <Link to="/Dashboard"  className="_button-small flex justify-center items-center mr-2 pt-[var(--Components-Button-Spacing-Y-SM,] pb-[var(--Components-Button-Spacing-X-SM,]  pr-[20px)] py-2  rounded-lg">
                
                <div className='w-[14px] hidden'>
                {JobName !=='' &&(<svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 10.5L5.25 7L8.75 3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>)}

                </div>
                
                <div className="placeholder text-center font-['Inter'] text-[.9375rem] font-semibold leading-[normal]">{getLocalizedString('dashboard', currentLanguage)}</div>
                </Link>

                <div className="">
                  <div className="_button-small-1 flex justify-center items-center pt-[var(--Components-Button-Spacing-Y-SM,] pb-[var(--Components-Button-Spacing-X-SM,]  rounded-lg">
                    {JobName && JobName!=='' &&( 
                    <svg width={14} height={14} className="rotate-180 path-gray-200 path-gray-400" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.75 10.5L5.25 7L8.75 3.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>)}
                    <div className="placeholder-1  text-center font-['Inter'] text-sm font-semibold leading-[normal]">{JobName}</div>

                {compoundMode && (
                <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V5C2 3.9 2.9 3 4 3H7.93C8.25941 3.0017 8.58331 3.08475 8.8729 3.24176C9.1625 3.39877 9.40882 3.62488 9.59 3.9L10.41 5.1C10.5912 5.37512 10.8375 5.60123 11.1271 5.75824C11.4167 5.91525 11.7406 5.9983 12.07 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V10.5"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 17H15C14.4477 17 14 17.4477 14 18V21C14 21.5523 14.4477 22 15 22H21C21.5523 22 22 21.5523 22 21V18C22 17.4477 21.5523 17 21 17Z"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 17V15C20 14.4696 19.7893 13.9609 19.4142 13.5858C19.0391 13.2107 18.5304 13 18 13C17.4696 13 16.9609 13.2107 16.5858 13.5858C16.2107 13.9609 16 14.4696 16 15V17"  className="stroke-[#666] dark:stroke-[#E5E7EB]" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>   
                )}
                  </div>
                </div>

            </div>

            <NewCase />
      </div>

)};

export default JobNav;
